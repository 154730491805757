import Vue from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import store from '../store/store';
import { COGNITO_ENABLED_MUTATION, COGNITO_FETCHED_MUTATION } from '../store/mutations';
import { COGNITO_ENABLED_GETTER, COGNITO_FETCHED_GETTER } from '../store/getters';

export default {
  $store: store,

  /**
   * Mutations from Vuex Store.
   */
  ...mapMutations([
    COGNITO_ENABLED_MUTATION,
    COGNITO_FETCHED_MUTATION,
  ]),

  /**
   * Getters from Vuex Storage.
   */
  ...mapGetters([
    COGNITO_ENABLED_GETTER,
    COGNITO_FETCHED_GETTER,
  ]),

  /**
   * Whether the application is running in the Cloud mode.
   */
  isInCloudMode() {
    return JSON.parse(process.env.VUE_APP_CLOUD);
  },

  async fetchCognitoEnabled() {
    const cogntioConfig = await Vue.axios.get('/api/auth/cognito/config');

    this[COGNITO_FETCHED_MUTATION](true);

    return cogntioConfig.data?.enabled ?? false;
  },

  isCognitoEnabled() {
    let newEnabled = this[COGNITO_ENABLED_GETTER]();

    try {
      if (!store.state.cognito.fetched) {
        this.fetchCognitoEnabled().then(enabled => {
          newEnabled = enabled;
          this[COGNITO_ENABLED_MUTATION](newEnabled);

          return newEnabled;
        });
      } else {
        return newEnabled;
      }
    } catch (error) {
      return newEnabled;
    }

    return newEnabled;
  },

  getCognitoClientId() {
    return process.env.VUE_APP_COGNITO_CLIENT_ID;
  },

  getCognitoUserPoolId() {
    return process.env.VUE_APP_COGNITO_USER_POOL_ID;
  },

  getCognitoRegion() {
    return process.env.VUE_APP_COGNITO_REGION;
  },
};
