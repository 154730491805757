<template>
  <v-container fluid
               class="password-reset"
  >
    <v-snackbar
      v-model="showMessage"
      timeout="8000"
      :color="colorMessage"
      top
      multi-line
    >
      {{ message }}
      <template #action>
        <v-btn
          icon
          @click="showMessage = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-card class="password-reset__container">
      <v-img
        src="@/assets/logo.png"
        class="image-pwd-reset"
      />

      <v-card-title class="password-reset__container--title">
        {{ $t('pageTitles.passwordReset') }}
      </v-card-title>

      <v-form
        v-model="valid"
        @submit.prevent="onChangePassword"
        @keyup.enter="onChangePassword"
      >
        <ValidationObserver
          ref="observer"
          v-slot="{ invalid }"
        >
          <!--
            Password Regex rules (same as cognito):
            - At least one digit
            - At least one special character
            - At least one lowercase letter
            - At least one uppercase letter
            - At least 8 characters
          -->
          <ValidationProvider
            v-slot="{ errors, valid }"
            name="newPassword"
            :rules="{
              regex: /^(?=.*\d)(?=.*\W)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
              required: true,
            }"
          >
            <v-text-field
              v-model="password"
              :error-messages="errors != ''
                ? `Password must be at least 8 characters long,
                contain at least one digit, one special character,
                one lowercase letter, and one uppercase letter.`
                : ''"
              :success="valid"
              :label="$t('passwordResetPage.enterPassword')"
              type="password"
              color="secondary"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors, valid }"
            :rules="{
              confirmed: 'newPassword',
              required: true,
            }"
            name="password_confirmation"
            vid="password_confirmation"
          >
            <v-text-field
              v-model="passwordConfirm"
              :error-messages="errors"
              :success="valid"
              type="password"
              :label="$t('passwordResetPage.confirmPassword')"
            />
          </ValidationProvider>
          <v-card-actions class="password-reset__container--actions">
            <v-btn
              :disabled="invalid"
              class="secondary white--text"
              @click="onChangePassword"
            >
              {{ $t('passwordResetPage.submit') }}
            </v-btn>
          </v-card-actions>

          <app-version class="password-reset__container--app-version" />
        </ValidationObserver>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import userService from '../js/services/userService';
import authService from '../js/services/authService';
import { ACCOUNTS_PAGE, LOGIN_PAGE } from '../js/router/pages';
import AppVersion from '../components/AppVersion.vue';
import applicationService from '../js/services/applicationService';

export default {
  name:       'PasswordReset',
  components: { AppVersion, ValidationObserver, ValidationProvider },
  data() {
    return {
      message:         '',
      showMessage:     false,
      colorMessage:    'success',
      password:        '',
      passwordConfirm: '',
    };
  },
  mounted() {
    // Fetch cognito state
    applicationService.isCognitoEnabled();
  },
  methods: {
    /**
     * Shows the error message notification.
     */
    showErrorMessage(msg) {
      this.showMessage = true;
      this.message = msg;
      this.colorMessage = 'error';
    },
    /**
     * Submit reset request.
     */
    async onChangePassword() {
      this.error = '';
      try {
        const resetData = {};

        resetData.email = this.$route.query.email;
        resetData.pswd_reset_guid = this.$route.query.token;
        resetData.new_password = this.password;

        await userService.updateUserPassword(resetData);
        await authService.login(this.$route.query.username, this.password);

        const userPageCheck = sessionStorage.getItem('userPageRedirect');

        if (userPageCheck != null && decodeURIComponent(window.location.href) === userPageCheck) {
          sessionStorage.removeItem('userPageRedirect');
          this.$router.push({ name: ACCOUNTS_PAGE });
        } else {
          this.$router.push({ name: LOGIN_PAGE });
        }
      } catch (res) {
        const errorMsg = res && res.data ? res.data : this.$t('passwordResetPage.error');

        this.showErrorMessage(errorMsg);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.password-reset {
  display: flex;
  align-items: center;
  height: 100vh;
  max-width: 26em;
  min-width: 18em;

  padding: 0;
  margin: 0 auto;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
    }

    &--actions {
      justify-content: center;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}
</style>
