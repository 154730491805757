import Accounts from '../../views/Accounts.vue';
import Account from '../../views/Account.vue';
import Capture from '../../views/Capture.vue';
import Captures from '../../views/Captures.vue';
import Tags from '../../views/Tags.vue';
import Login from '../../views/Login.vue';
import UserPage from '../../views/UserPage.vue';
import Settings from '../../views/Settings.vue';
import PasswordReset from '../../views/PasswordReset.vue';
import ForgotPassword from '../../views/ForgotPassword.vue';
import VideoStudioReview from '../../views/VideoStudioReview.vue';
import ImageStudioReview from '../../views/ImageStudioReview.vue';
import ImageStudio from '../../views/ImageStudio.vue';
import DermaidStudio from '../../views/DermaidStudio.vue';
import VideoStudio from '../../views/VideoStudio.vue';
import ExportCaptureDownload from '../../views/ExportCaptureDownload.vue';

import {
  ACCOUNTS_PAGE,
  ACCOUNT_PAGE,
  CAPTURE_PAGE,
  CAPTURES_PAGE,
  TAGS_PAGE,
  LOGIN_PAGE,
  MFA_PAGE,
  MFA_SETUP_PAGE,
  USER_PAGE,
  SETTINGS_PAGE,
  PASSWORD_RESET_PAGE,
  FORGOT_PASSWORD_PAGE,
  VIDEO_STUDIO_REVIEW_PAGE,
  IMAGE_STUDIO_REVIEW_PAGE,
  IMAGE_STUDIO_PAGE,
  DERMAID_STUDIO_PAGE,
  VIDEO_STUDIO_PAGE,
  EXPORT_CAPTURE_DOWNLOAD_PAGE,
  LOGOUT_PAGE,
} from './pages';
import Logout from '../../views/Logout.vue';
import MFASetup from '../../views/MFASetup.vue';
import MFA from '../../views/MFA.vue';

export default [
  {
    path:      '/accounts',
    name:      ACCOUNTS_PAGE,
    component: Accounts,
    meta:      {
      title:       'pageTitles.accounts',
      breadcrumbs: 'search',
    },
  },
  {
    path:      '/accounts/:accountId',
    name:      ACCOUNT_PAGE,
    component: Account,
    meta:      {
      title:       'pageTitles.account',
      breadcrumbs: 'account',
    },
  },
  {
    path:      '/captures/:captureId',
    name:      CAPTURE_PAGE,
    component: Capture,
    meta:      {
      title:       'pageTitles.capture',
      breadcrumbs: 'capture',
    },
  },
  {
    path:      '/captures/',
    name:      CAPTURES_PAGE,
    component: Captures,
    meta:      {
      title:       'pageTitles.captures',
      breadcrumbs: 'captures',
    },
  },
  {
    path:      '/tags/',
    name:      TAGS_PAGE,
    component: Tags,
    meta:      {
      title:       'pageTitles.tags',
      breadcrumbs: 'tags',
    },
  },
  {
    path:      '/login',
    name:      LOGIN_PAGE,
    component: Login,
    meta:      {
      title:         'pageTitles.login',
      withOutLayout: true,
      withOutLogin:  true,
    },
  },
  {
    path:      '/mfa',
    name:      MFA_PAGE,
    component: MFA,
    meta:      {
      title:         'pageTitles.mfa',
      withOutLayout: true,
      withOutLogin:  true,
    },
  },
  {
    path:      '/mfa/setup',
    name:      MFA_SETUP_PAGE,
    component: MFASetup,
    meta:      {
      title:         'pageTitles.mfaSetup',
      withOutLayout: true,
      withOutLogin:  true,
    },
  },
  {
    path:      '/logout',
    name:      LOGOUT_PAGE,
    component: Logout,
    meta:      {
      title:         'Logged Out',
      withOutLayout: true,
      withOutLogin:  true,
    },
  },
  {
    path:      '/userSettings/',
    name:      USER_PAGE,
    component: UserPage,
    meta:      {
      title: 'pageTitles.userPage',
    },
  },
  {
    path:      '/settings/',
    name:      SETTINGS_PAGE,
    component: Settings,
    meta:      {
      title:       'pageTitles.settings',
      checkAccess: true,
    },
  },
  {
    path:      '/forgotPassword/',
    name:      FORGOT_PASSWORD_PAGE,
    component: ForgotPassword,
    meta:      {
      title:         'pageTitles.forgotPassword',
      withOutLayout: true,
      withOutLogin:  true,
    },
  },
  {
    path:      '/passwordReset/',
    name:      PASSWORD_RESET_PAGE,
    component: PasswordReset,
    meta:      {
      title:         'pageTitles.passwordReset',
      withOutLayout: true,
      withOutLogin:  true,
    },
  },
  {
    path:      '/videoStudioReview/:videoStudioId',
    name:      VIDEO_STUDIO_REVIEW_PAGE,
    component: VideoStudioReview,
    meta:      {
      title: 'pageTitles.videoStudioReview',
    },
  },
  {
    path:      '/imageStudioReview/:imageStudioId',
    name:      IMAGE_STUDIO_REVIEW_PAGE,
    component: ImageStudioReview,
    meta:      {
      title: 'pageTitles.imageStudioReview',
    },
  },
  {
    path:      '/imageStudio/',
    name:      IMAGE_STUDIO_PAGE,
    component: ImageStudio,
    meta:      {
      title: 'pageTitles.imageStudio',
    },
  },
  {
    path:      '/dermaidStudio/',
    name:      DERMAID_STUDIO_PAGE,
    component: DermaidStudio,
    props:     { dermaid: true },
    meta:      {
      title: 'pageTitles.dermaidStudio',
    },
  },
  {
    path:      '/videoStudio/',
    name:      VIDEO_STUDIO_PAGE,
    component: VideoStudio,
    meta:      {
      title: 'pageTitles.videoStudio',
    },
  },
  {
    path:      '/exportCaptureDownload/:exportCaptureId',
    name:      EXPORT_CAPTURE_DOWNLOAD_PAGE,
    component: ExportCaptureDownload,
    meta:      {
      title: 'pageTitles.exportCaptureDownload',
    },
  },
];
