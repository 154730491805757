export const ACCOUNTS_PAGE = 'accounts';
export const ACCOUNT_PAGE = 'account';
export const CAPTURE_PAGE = 'capture';
export const CAPTURES_PAGE = 'captures';
export const TAGS_PAGE = 'tags';
export const LOGIN_PAGE = 'login';
export const MFA_PAGE = 'mfa';
export const MFA_SETUP_PAGE = 'mfaSetup';
export const LOGOUT_PAGE = 'logout';
export const FORGOT_PASSWORD_PAGE = 'forgotPasswordReset';
export const PASSWORD_RESET_PAGE = 'passwordReset';
export const USER_PAGE = 'userSettings';
export const SETTINGS_PAGE = 'globalSettings';
export const VIDEO_STUDIO_REVIEW_PAGE = 'videoStudioReview';
export const IMAGE_STUDIO_REVIEW_PAGE = 'imageStudioReview';
export const IMAGE_STUDIO_PAGE = 'imageStudio';
export const DERMAID_STUDIO_PAGE = 'dermaidStudio';
export const VIDEO_STUDIO_PAGE = 'videoStudio';
export const EXPORT_CAPTURE_DOWNLOAD_PAGE = 'exportCaptureDownload';
