export default {
  /**
   * Convert base64 string to byte array.
   *
   * @param {string} data - Base64 string.
   *
   * @return {ArrayBuffer}
   */
  base64ToArrayBuffer(data) {
    const str2ab = str => {
      const buf = new ArrayBuffer(str.length);
      const bufView = new Uint8Array(buf);

      for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
      }

      return buf;
    };

    // Decode the Base64 string to a binary string
    const binaryString = atob(data);

    return str2ab(binaryString);
  },

  /**
   * Encrypt string with RSA public key.
   *
   * @param {string} data - Data to encrypt.
   * @param {ArrayBuffer} publicKey - RSA public key.
   *
   * @return {Promise<string>}
   */
  async encryptStringWithRSA(data, publicKey) {
    const encoder = new TextEncoder();

    const importedKey = await window.crypto.subtle.importKey(
      'spki',
      publicKey,
      {
        name: 'RSA-OAEP',
        hash: 'SHA-256',
      },
      true,
      ['encrypt'],
    );

    const encodedData = encoder.encode(data);

    const encryptedData = await window.crypto.subtle.encrypt(
      {
        name: 'RSA-OAEP',
      },
      importedKey,
      encodedData,
    );

    const encryptedArray = new Uint8Array(encryptedData);
    const encryptedString = btoa(String.fromCharCode(...encryptedArray));

    return encryptedString;
  },
};
