<template>
  <v-container
    fluid
    class="login"
  >
    <v-snackbar
      v-model="showMessage"
      timeout="8000"
      :color="colorMessage"
      top
      multi-line
    >
      {{ message }}
      <template #action>
        <v-btn
          icon
          @click="showMessage = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-card class="login__container mx-auto my-12">
      <v-img
        class="flex"
        max-width="539"
        src="@/assets/logo_LG.png"
      />

      <div class="text-center">
        <v-card-title class="login__container--title">
          {{ $t('pageTitles.mfa') }}
        </v-card-title>

        <p class="login__container--title">
          {{ $t('mfa.enterCode') }}
        </p>
      </div>

      <div>
        <TOTPInput
          :id="id"
          :username="username"
          :session="session"
          :init="false"
          @verify-error="showErrorMessage"
        />
      </div>

      <app-version class="login__container--app-version" />
    </v-card>
  </v-container>
</template>

<script>
import AppVersion from '../components/AppVersion.vue';
import TOTPInput from '../components/TOTPInput.vue';
import { LOGIN_PAGE } from '../js/router/pages';
import applicationService from '../js/services/applicationService';

export default {
  name:       'Login',
  components: { AppVersion, TOTPInput },
  data() {
    return {
      cognitoEnabled: applicationService.isCognitoEnabled(),
      message:        '',
      showMessage:    false,
      colorMessage:   'success',
      id:             this.$route.query.id,
      username:       this.$route.query.username,
      session:        sessionStorage.getItem('mfaSession'),
      code:           '',
    };
  },
  mounted() {
    if (!this.cognitoEnabled) {
      this.$router.push({ name: LOGIN_PAGE });
    }
  },
  methods: {
    /**
     * Shows the error message notification.
     */
    showErrorMessage(msg) {
      this.showMessage = true;
      this.message = msg;
      this.colorMessage = 'error';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../css/variables";

.text-center {
  text-align: center;
}

.login {
  display: flex;
  align-items: center;
  max-width: 26em;
  padding: 0;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
      padding-bottom: 0%;
    }

    &--actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--qr-code {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

.bold {
  font-weight: 500;
}

</style>
