import Vue from 'vue';
import Router from 'vue-router';
import {
  LOGIN_PAGE, ACCOUNTS_PAGE, LOGOUT_PAGE,
} from './pages';
import routes from './routes';
import authService from '../services/authService';
import i18n from '../lang/i18n';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/**
 * Redirect user to login page if user is not authorized and page require authorization.
 * Set page title.
 *
 * @param {Route} to - The target Route Object being navigated to.
 * @param {Route} from - Function that must be called to resolve the hook.
 * @param {function} next
 */
router.beforeEach((to, from, next) => {
  if (
    !to.meta.withOutLogin
    && !authService.isAuthenticated()
    && to.name !== LOGIN_PAGE
    && to.name !== LOGOUT_PAGE
  ) {
    if (to.name) {
      authService.saveLoginRedirectRoute(to);
    }
    next({ name: LOGIN_PAGE });
  } else if (to.meta.checkAccess && !authService.checkAccess(to)) {
    next({ name: ACCOUNTS_PAGE });
  } else if (!to.name) {
    next({ name: ACCOUNTS_PAGE });
  } else {
    next();
  }
});

router.afterEach(to => {
  if (to.meta.title) {
    document.title = `${i18n.t('pageTitles.common')} ${i18n.t(to.meta.title)}`;
  } else {
    document.title = `${i18n.t('pageTitles.common')}`;
  }
});

export default router;
