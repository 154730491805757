var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"password-reset",attrs:{"fluid":""}},[_c('v-snackbar',{attrs:{"timeout":"8000","color":_vm.colorMessage,"top":"","multi-line":""},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.showMessage = false}}},[_c('v-icon',[_vm._v("close")])],1)]},proxy:true}]),model:{value:(_vm.showMessage),callback:function ($$v) {_vm.showMessage=$$v},expression:"showMessage"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('v-card',{staticClass:"password-reset__container"},[_c('v-img',{staticClass:"image-pwd-reset",attrs:{"src":require("@/assets/logo.png")}}),_c('v-card-title',{staticClass:"password-reset__container--title"},[_vm._v(" "+_vm._s(_vm.$t('pageTitles.passwordReset'))+" ")]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onChangePassword.apply(null, arguments)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onChangePassword.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('ValidationProvider',{attrs:{"name":"newPassword","rules":{
            regex: /^(?=.*\d)(?=.*\W)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            required: true,
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"error-messages":errors != ''
              ? `Password must be at least 8 characters long,
              contain at least one digit, one special character,
              one lowercase letter, and one uppercase letter.`
              : '',"success":valid,"label":_vm.$t('passwordResetPage.enterPassword'),"type":"password","color":"secondary"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":{
            confirmed: 'newPassword',
            required: true,
          },"name":"password_confirmation","vid":"password_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"error-messages":errors,"success":valid,"type":"password","label":_vm.$t('passwordResetPage.confirmPassword')},model:{value:(_vm.passwordConfirm),callback:function ($$v) {_vm.passwordConfirm=$$v},expression:"passwordConfirm"}})]}}],null,true)}),_c('v-card-actions',{staticClass:"password-reset__container--actions"},[_c('v-btn',{staticClass:"secondary white--text",attrs:{"disabled":invalid},on:{"click":_vm.onChangePassword}},[_vm._v(" "+_vm._s(_vm.$t('passwordResetPage.submit'))+" ")])],1),_c('app-version',{staticClass:"password-reset__container--app-version"})]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }